// extracted by mini-css-extract-plugin
export var datasheetRowWrapperCenter = "t_s0 d_bD";
export var datasheetRowWrapper = "t_lt d_lt d_bK";
export var datasheetLeftWrapper = "t_s1 d_bz d_bP";
export var datasheetWrapperFull = "t_s2 d_cD";
export var datasheetWrapperFullLeft = "t_pf d_cD d_bz d_bP d_bD d_bT";
export var contentWrapper = "t_mv d_lv";
export var contentWrapperCenter = "t_s3 d_lz";
export var datasheetLeftWrapperCenter = "t_s4 d_lx d_bz d_bD d_bP d_bJ";
export var datasheetImageCenterWrapper = "t_lr d_lr d_w d_cv";
export var datasheetRightWrapperCenter = "t_s5 d_lw";
export var datasheetFooter = "t_ly d_ly";
export var alignLeft = "t_qh d_dv";
export var alignCenter = "t_bP d_dw";
export var alignRight = "t_qj d_dx";
export var datasheetInnerWrapperNewLeft = "t_s6 d_ff d_P d_Z d_bz d_bP d_dj";
export var datasheetInnerWrapperLeft = "t_s7 d_fd d_P d_Z d_bz d_bP d_b5 d_dj d_c9";
export var datasheetInnerWrapperRight = "t_s8 d_ff d_P d_Z d_bz d_bP d_dj";
export var datasheetRowWrapperReverse = "t_s9 d_bK";
export var datasheetSubtitle = "t_tb d_cx";
export var tableRow = "t_tc";
export var cell = "t_td";
export var tableRowWrapper = "t_tf d_w d_dv";
export var tableHeadingCell = "t_tg";
export var tableHeading = "t_th";
export var tableRowStriped = "t_tj";
export var tableRowHeading = "t_tk";
export var dataTable = "t_tl d_w";
export var imageWrapper = "t_tm d_fg d_Z";
export var imageWrapperFull = "t_tn d_H d_w d_bf d_Z";
export var imageWrapperIcon = "t_tp";
export var titleMargin = "t_tq d_cs";
export var datasheetInnerInnerWrapper = "t_tr d_w";
export var hasLabels = "t_ts";
export var label = "t_tt";
export var SmallSmall = "t_rf q_rf q_qr q_qP";
export var SmallNormal = "t_rg q_rg q_qr q_qQ";
export var SmallLarge = "t_rh q_rh q_qr q_qM";